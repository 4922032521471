export default {
  ActivityOverview: 'Activity overview',
  AddToStoreNavbar: 'Add to store navbar',
  ComingSoon: 'Coming soon',
  ConfigureLiveShoppingPageModal: {
    Step1: '1. In Navigation tab, select Main Menu',
    Step2: '2. Add Menu Item (page)',
    Step3: '3. Label the new page and select Live Shopping in the list',
    Step4: '4. Clic on Add and finally Save',
    Title: 'Display the Live Shopping Page on your Shopify',
  },
  ConfigureMenu: 'Configure my Menu',
  CopyLinkToEvent: 'Copy link to Event',
  CreateLive: {
    Title: 'Go live!',
  },
  CreateLiveAction: 'Create Live Event',
  CreateLiveShoppingPage: 'Create Live Shopping Page',
  CreateShoppableAction: 'Create shoppable',
  DisplayOnShopify: 'Display on Shopify',
  EventSettings: 'Event settings',
  Hello: 'Hello!',
  LiveEvents: 'Live events',
  NoPlannedEvent: 'No event is planned',
  Notifications: {
    NoPlayerPlaylistsBlock:
      'To enable playlists of Shoppable videos, please activate our bloc "Playlists" on your Shopify.',
    ShoppableWidgetNotYetActive: 'Your shoppable widget is not yet active.',
    ShoppingPageCreated:
      'Live Shopping page is ready to be added in your top menu! Have you made it visible on your store yet?',
    ShoppingPageNotFound: 'The Live Shopping Page was not found on your Shopify.',
  },
  Plan: {
    SeeAllPlans: 'See all plans',
    YourPlan: 'You’re on {{planName}} plan.',
  },
  ProductPagesWithMediaCollectionsCount: '{{count}}/{{maxCount}} product pages with videos',
  ProductPagesWithMediaCollectionsCountTitle: 'Videos everywhere?',
  ReleaseNotes: {
    Title: 'New Features :',
    'v0.15':
      'We have (again) some new things for you!\n<Category>Live Now widget:</Category> Add our nice widget on your store so that any visitor is aware that you are selling live now.\n<Category>More Analytics:</Category> Track the success of your Shoppable videos in the dedicated section <ShoppableVideoAnalyticsLink>here</ShoppableVideoAnalyticsLink>.\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a “Creator” account.',
    'v0.15-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
    'v0.17':
      "We have (again) some new things for you!\n<Category>Widget Carousel:</Category>  A new block to add on your store! Display shoppable videos in poster format on your Shopify to boost traffic and sales.\n<Category>Shoppable Playlist swipe:</Category>  As on TikTok or IG, your clients can swipe through all your videos ... and still buy your products. So don't be shy and <OpenShoppablesLink>add more shoppable videos</OpenShoppablesLink> to your home, product and collection pages.",
    'v0.17-NonShopify':
      'We have (again) some new things for you!\n<Category>Restream anywhere:</Category> Go beyond IG, FB and YT and stream onto any platform where you have a "Creator" account.',
  },
  Replays: 'Replays',
  ShoppableVideos: 'Shoppable videos',
  Shoppables: 'Shoppables',
  ShoppablesCount: '{{count}} displayed videos',
  ShoppablesCountWithMaxCount: '{{count}}/{{maxCount}} displayed videos',
  TotalAmountInCheckout: 'Total in checkout',
  TotalOrders: 'Total orders',
  TotalSales: 'Total sales',
  TotalViews: 'Total views',
  Tutorial: 'Tutorial',
  UpcomingLive: 'Upcoming live',
  UpgradeForMore: 'Upgrade for more',
  ValidatedCarts: 'Validated carts',
};
