import { z } from 'zod';
import { EventStatus } from '../event';
import { RealtimePinnedMessage, RealtimePinnedMessageSchema } from '../pinned-message';
import { ProductDTO, ProductDTOSchema } from '../product';
import { Replay } from '../replay';
import { EventMetadata, EventMetadataSchema } from './EventMetadata';
import { StringSchema } from '../shared';

/**
 * <!> Some stats like the calculation of the averageAttendance time and billing use these constant.
 * <!> Changing their values will render the older stats incorrect.
 */
export const FIRST_PING_DELAY = 5000;
export const PING_DURATION = 10000;

export type TestAndLaterRealtimeData =
  | PrivatePreviewRealtimeData
  | OnAirRealtimeData
  | FinishedRealtimeData
  | ReplayReadyRealtimeData
  | PrivateReplayRealtimeData
  | ReplayRealtimeData;
export type RealtimeData = PlannedRealtimeData | TestAndLaterRealtimeData;

export const PlannedRealtimeDataSchema = EventMetadataSchema.extend({
  showTime: StringSchema.datetime(),
  status: z.literal(EventStatus.PLANNED),
});

export const PrivatePreviewRealtimeDataSchema = EventMetadataSchema.extend({
  chatModeratorIds: StringSchema.array(),
  pinnedChatMessages: RealtimePinnedMessageSchema.array().optional(),
  productHighlights: StringSchema.array(),
  products: ProductDTOSchema.array(),
  showTime: StringSchema.datetime().optional(),
  startTime: StringSchema.datetime().optional(),
  status: z.literal(EventStatus.PRIVATE_PREVIEW),
  viewersCount: z.number().nullable().optional(),
});

export interface OnAirRealtimeData extends EventMetadata {
  chatModeratorIds: string[];
  pinnedChatMessages?: RealtimePinnedMessage[];
  productHighlights: string[];
  products: ProductDTO[];
  showTime?: string;
  startTime: string;
  status: EventStatus.ON_AIR;
  streamToken: string;
  viewersCount?: number | null;
}

export interface FinishedRealtimeData extends EventMetadata {
  products: ProductDTO[];
  status: EventStatus.FINISHED;
}

export interface ReplayReadyRealtimeData extends Replay {
  status: EventStatus.REPLAY_READY;
}

export interface PrivateReplayRealtimeData extends Replay {
  status: EventStatus.PRIVATE_REPLAY;
}

export interface ReplayRealtimeData extends Replay {
  firstFrameUrl: string | null;
  status: EventStatus.REPLAY;
}

export type PlannedRealtimeData = z.infer<typeof PlannedRealtimeDataSchema>;
export type PrivatePreviewRealtimeData = z.infer<typeof PrivatePreviewRealtimeDataSchema>;
